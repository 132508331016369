html {
    /* base font-size, WARNING: all rem sizes are relative to this constant */
  font-size: 16px;

}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
